import Cookies from 'js-cookie';

document.addEventListener('DOMContentLoaded', () => {

    const quickExitBtns = document.querySelectorAll('[data-quick-exit]');

    [...quickExitBtns].forEach((quickExitBtn) => {
        quickExitBtn.addEventListener('click', (event) => {
            event.preventDefault();
            Cookies.remove('siteMessage');
            document.documentElement.innerHTML = '';
            location.replace('https://www.google.com.au/');
        });
    });
});
