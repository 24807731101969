import Cookies from 'js-cookie';

document.addEventListener('DOMContentLoaded', () => {

    const alerts = document.querySelectorAll('[data-popup]');

    const faedIn = (elem) => {
        const elementattr = Number(getComputedStyle(elem).opacity);

        if (elementattr >= 1) {
            elem.style.display = 'block';
            return;
        }
        elem.style.opacity = elementattr + 0.05;
        setTimeout(() => {
            faedIn(elem);
        }, 0.5);
    };

    const faedOut = (elem) => {
        const elementattr = Number(getComputedStyle(elem).opacity);

        if (elementattr <= 0) {
            elem.style.display = 'none';
            return;
        }
        elem.style.opacity = elementattr - 0.05;
        setTimeout(() => {
            faedOut(elem);
        }, 0.5);
    };

    [...alerts].forEach((alert) => {

        const alertId = alert.getAttribute('data-popup'),
            closePopupButton = document.querySelector(`[data-dismiss-popup="${alertId}"]`),
            dismissAlertOverlay = document.querySelector(`[data-popup="${alertId}"]`);

        if (closePopupButton) {
            closePopupButton.addEventListener('click', () => {
                Cookies.set(`${alertId}`, true, { expires: 7 });
                if (alertId == 'siteMessage') {
                    faedOut(dismissAlertOverlay);
                } else {
                    faedIn(dismissAlertOverlay);
                }
            });
        }

        // if (dismissAlertOverlay) {
        //     dismissAlertOverlay.addEventListener('click', () => {
        //         // Cookies.set(`${alertId}`, true, { expires: 7 });
        //         if (alertId == 'siteMessage') {
        //             faedOut(dismissAlertOverlay);
        //         } else {
        //             faedIn(dismissAlertOverlay);
        //         }
        //     });
        // }

        // document.addEventListener('keydown', (event) => {
        //     if (event.key === 'Escape') {
        //         // Cookies.set(`${alertId}`, true, { expires: 7 });
        //         if (alertId == 'siteMessage') {
        //             faedOut(dismissAlertOverlay);
        //         } else {
        //             faedIn(dismissAlertOverlay);
        //         }
        //     }
        // });
    });
});
