// Remove our preload class from the body, preventing early CSS transitions
import '@utils/preload';

// CSS needs to be imported here as it's treated as a module
import '@/scss/style.scss';

// ================================================
// All third-party, globally-required libraries should be loaded here
// ================================================

// Support for lazyload images (and bg images)
import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';
import 'lazysizes/plugins/bgset/ls.bgset';

// Flickity - please use `data-flickity` options to configure
import 'flickity';
import 'flickity-bg-lazyload';

// An excellent, responsive and lightweight lightbox
import 'spotlight.js';
import './modules/alertBar';
import './modules/quickexit';
import './modules/megamenu';

// ================================================
// SUI Components
// ================================================

import MobileNav from '@sgroup/mobile-nav';
import ClickToggle from '@sgroup/click-toggle';


// ================================================
// Setup Vue3 - Use only if this project needs Vue!
// ================================================
import { createApp } from 'vue';

// Import all globally-available components here. Be responsible please.
import {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    TabGroup,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
} from '@headlessui/vue';

createApp({
    delimiters: ['${', '}'],

    components: {
        HuiDisclosure: Disclosure,
        HuiDisclosureButton: DisclosureButton,
        HuiDisclosurePanel: DisclosurePanel,
        HuiTabGroup: TabGroup,
        HuiTabList: TabList,
        HuiTab: Tab,
        HuiTabPanels: TabPanels,
        HuiTabPanel: TabPanel,
    },

    data() {
        return {
            navActive: false,
        };
    },

    mounted() {
        // Because we're using Vue, anything that interacts with the DOM should go here.

        // A minimal responsive menu with just vanilla JS
        new MobileNav();

        // A search box for header. Handles clicking away
        new ClickToggle({
            openSelector: '[data-search-open]',
            activeClass: 'search-open',
            clickAwaySelector: '[data-header-search-form]',
        });

        // Disabling back button
        document.querySelectorAll('a').forEach(((link) => {
            link.addEventListener('click', (e) => {
                const url = e.currentTarget.href;
                if (url != '#' || url != 'javascript:;') {
                    e.preventDefault();
                    window.location.replace(url);
                }
            }, true);
        }));

        // Resizing header
        function resizeHeaderOnScroll() {
            const distanceY = window.pageYOffset || document.documentElement.scrollTop,
                shrinkOn = 100,
                headerEl = document.querySelector('[data-header]');

            if (distanceY > shrinkOn) {
                headerEl.classList.add('header--small');
            } else {
                headerEl.classList.remove('header--small');
            }
        }

        window.addEventListener('scroll', resizeHeaderOnScroll);
    },
}).mount('#app');
